
import * as _ from 'lodash'
import {
    defineComponent,
    ref,
    PropType,
    computed,
    nextTick,
    watch,
    shallowRef,
    Ref,
    getCurrentInstance,
} from 'vue'
import BaseInputOutlined from '@/components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '@/components/inputs/CustomActivatorSelect.vue'
import useRouter from '@/hooks/useRouter'
import { checkInputIsIntNumber } from '@/utils'
import { AvgCheckReportMappedSetting } from '@/types/avgCheckReport'
import StaffServiceModal from '../StaffServiceModal.vue'

export default defineComponent({
    name: 'AvgCheckReportSchemaSettingStaffStep',
    components: { BaseInputOutlined, CustomActivatorSelect, StaffServiceModal },
    props: {
        value: {
            type: Object as PropType<AvgCheckReportMappedSetting>,
            required: true,
        },
        staffList: { type: Array as PropType<{ id: string; name: string }[]>, required: true },
        serviceList: { type: Array as PropType<{ id: string; name: string }[]>, required: true },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { route } = useRouter(root)
        const openCreateMenu = () => {
            ;(root.$refs as any)['createSelect'].openMenu()
        }
        const staffServiceModal = ref<any>({ isOpened: false, staffId: undefined })
        const onCreated = async () => {}
        const servicelistMap = computed(() => new Map(props.serviceList.map(el => [el.id, el.name])))
        const stafflistMap = computed(() => new Map(props.staffList.map(el => [el.id, el.name])))
        const existedStaffIds = computed(() => props.value.staff.map(el => el.id))
        const staffSettings = computed(() => props.value.staff)

        const getSettingInputText = (selected: any) => {
            if (!selected) return ''
            if (Array.isArray(selected)) return `Выбрано ${selected.length}`
            else return selected.name
        }
        const openStaffServiceModal = (staff: { id: string; name: string }) => {
            staffServiceModal.value.isOpened = true
            staffServiceModal.value.staffId = staff.id
        }
        const updateStaff = (currentStaffId: string, staffId: string) => {
            const copy = _.cloneDeep(props.value)
            const staff = copy.staff.find(s => s.id === currentStaffId)
            if (!staff) return
            staff.id = staffId
            emit('input', copy)
        }
        const removeStaff = (staffId: string) => {
            const copy = _.cloneDeep(props.value)
            const staffIndex = copy.staff.findIndex(staff => staff.id === staffId)
            if (staffIndex < 0) return
            copy.staff.splice(staffIndex, 1)
            emit('input', copy)
        }
        const removeServiceFromStaff = ({ staffId, serviceId }: any) => {
            const copy = _.cloneDeep(props.value)
            const staff = copy.staff.find(staff => staff.id === staffId)
            if (!staff) return
            const serviceIndex = staff.services.findIndex(el => el.id === serviceId)
            if (serviceIndex < 0) return
            staff.services.splice(serviceIndex, 1)
            emit('input', copy)
        }
        const addStaffService = ({ staffId, serviceId }: any) => {
            const copy = _.cloneDeep(props.value)
            const staff = copy.staff.find(staff => staff.id === staffId)
            if (!staff) return
            staff.services.push({
                id: serviceId,
                name: servicelistMap.value.get(serviceId) || '',
                avgCheck: { min: 0, max: 0 },
            })
            emit('input', copy)
        }
        const updateStaffService = ({ currentServiceId, newServiceId, staffId }: any) => {
            const copy = _.cloneDeep(props.value)
            const staff = copy.staff.find(staff => staff.id === staffId)
            if (!staff) return
            const service = staff.services.find(s => s.id === currentServiceId)
            if (!service) return
            service.id = newServiceId

            emit('input', copy)
        }
        const addStaff = (staffId: string) => {
            const copy = _.cloneDeep(props.value)
            copy.staff.push({
                id: staffId,
                name: stafflistMap.value.get(staffId) as string,
                avgCheck: { min: 0, max: 0 },
                services: [],
            })

            emit('input', copy)
        }

        const getAvailabeStaff = (staffId?: string) => {
            return props.staffList.map(el =>
                Object.assign({}, el, {
                    disabled: staffId === el.id ? false : existedStaffIds.value.includes(el.id),
                })
            )
        }
        onCreated()
        return {
            openCreateMenu,
            getAvailabeStaff,
            existedStaffIds,
            staffSettings,
            addStaff,
            getSettingInputText,
            upperCase: _.upperCase,
            set: _.set,
            updateStaff,
            removeStaff,
            checkInputIsIntNumber,
            staffServiceModal,
            openStaffServiceModal,
            removeServiceFromStaff,
            addStaffService,
            updateStaffService,
        }
    },
})
