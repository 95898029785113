
import * as _ from 'lodash'
import {
    defineComponent,
    ref,
    PropType,
    computed,
    nextTick,
    watch,
    shallowRef,
    Ref,
    getCurrentInstance,
} from 'vue'
import { KpiSchemeActionId, WorkingFormatKpiSetting } from '@/types/GCB2'
import BaseInputOutlined from '@/components/inputs/BaseInputOutlined.vue'
import { v4 as uuidv4 } from 'uuid'
import CustomActivatorSelect from '@/components/inputs/CustomActivatorSelect.vue'
import useRouter from '@/hooks/useRouter'
import { checkInputIsNumber, replaceElementAtIndex } from '@/utils'
import { AvgCheckReportMappedSetting } from '@/types/avgCheckReport'
const CATEGORY = {
    1: 'Основная услуга',
    2: 'Дополнительная услуга',
    3: 'Премиальная услуга',
    4: 'Комплексная услуга',
    5: 'Товар',
}
export default defineComponent({
    name: 'AvgCheckReportSchemaSettingCategoryStep',
    components: { BaseInputOutlined, CustomActivatorSelect },
    props: {
        value: {
            type: Object as PropType<AvgCheckReportMappedSetting>,
            required: true,
        },
        staffList: { type: Array as PropType<{ id: string; name: string }[]>, required: true },
        serviceList: { type: Array as PropType<{ id: string; name: string }[]>, required: true },
        category: { type: Number, required: true },
    },
    setup(props, { emit,  }) {
        const root = getCurrentInstance()!.proxy
        const { route } = useRouter(root)
        const openCreateMenu = () => {
            ;(root.$refs as any)['createSelect'].openMenu()
        }
        const onCreated = async () => {}
        const servicelistMap = computed(() => new Map(props.serviceList.map(el => [el.id, el.name])))
        const existedServicesIds = computed(() => props.value.services.map(el => el.id))
        const categoryServices = computed(() =>
            props.value.services.filter(el => el.category.id === props.category)
        )
        const serviceSettingLabel = computed(() => {
            if (props.category === 3) return 'Базовая услуга'
            if (props.category === 4) return 'Услуги'
        })
        const columns = computed(() => {
            if (props.category === 3 || props.category === 4) return [6, 5]
            return [11, 1]
        })
        const showServiceSetting = computed(() => {
            if (props.category === 3 || props.category === 4) return true
            return false
        })
        const getServiceSetting = (service: any) => {
            if (props.category === 3) return service.basicService
            if (props.category === 4) return service.consistsOf
            return undefined
        }
        const setServiceSetting = (service: any, val: any) => {
            if (props.category === 3) service.basicService = val
            if (props.category === 4) service.consistsOf = val
            return undefined
        }
        const getSettingInputText = (selected: any) => {
            if (!selected) return ''
            if (Array.isArray(selected)) return `Выбрано ${selected.length}`
            else return selected.name
        }
        const settingIsMultiple = computed(() => {
            if (props.category === 4) return true
            return false
        })
        const updateService = (currentServiceId: string, serviceId: string) => {
            const copy = _.cloneDeep(props.value)
            const service = copy.services.find(service => service.id === currentServiceId)
            if (!service) return
            service.id = serviceId
            emit('input', copy)
        }
        const removeService = (serviceId: string) => {
            const copy = _.cloneDeep(props.value)
            const serviceIndex = copy.services.findIndex(service => service.id === serviceId)
            if (serviceIndex < 0) return
            copy.services.splice(serviceIndex, 1)
            emit('input', copy)
        }
        const addServiceToCategory = (serviceId: string) => {
            const copy = _.cloneDeep(props.value)
            copy.services.push(
                _.omitBy(
                    {
                        id: serviceId,
                        name: servicelistMap.value.get(serviceId) as string,
                        category: {
                            id: props.category,
                            name: _.get(CATEGORY, props.category),
                        },
                        basicService: props.category === 3 ? null : undefined,
                        consistsOf: props.category === 4 ? [] : undefined,
                    },
                    _.isUndefined
                ) as any
            )

            emit('input', copy)
        }
        const getAvailabeServices = (serviceId?: string) => {
            return props.serviceList.map(el =>
                Object.assign({}, el, {
                    disabled: serviceId === el.id ? false : existedServicesIds.value.includes(el.id),
                })
            )
        }
        onCreated()
        return {
            checkInputIsNumber,
            openCreateMenu,
            getAvailabeServices,
            existedServicesIds,
            categoryServices,
            addServiceToCategory,
            serviceSettingLabel,
            columns,
            showServiceSetting,
            getServiceSetting,
            setServiceSetting,
            settingIsMultiple,
            getSettingInputText,
            upperCase: _.upperCase,
            updateService,
            removeService,
        }
    },
})
