
import { computed, defineComponent, getCurrentInstance, PropType } from 'vue'
import BaseInputOutlined from '@/components/inputs/BaseInputOutlined.vue'
import CustomActivatorSelect from '@/components/inputs/CustomActivatorSelect.vue'
import { AvgCheckReportMappedSetting } from '@/types/avgCheckReport'
import * as _ from 'lodash'
import { toPercent, fromPercent, checkInputIsNumber } from '@/utils'
export default defineComponent({
    name: 'AvgCheckReportSchemaSettingStaffStep',
    components: { BaseInputOutlined, CustomActivatorSelect },
    props: {
        settings: {
            type: Object as PropType<AvgCheckReportMappedSetting>,
            required: true,
        },
        staffId: { type: String },
        value: { type: Boolean, required: true },
        staffList: { type: Array as PropType<{ id: string; name: string }[]>, required: true },
        serviceList: { type: Array as PropType<{ id: string; name: string }[]>, required: true },
    },
    setup(props, { emit,  }) {
        const root = getCurrentInstance()!.proxy
        const modalIsOpened = computed({
            get: () => props.value,
            set: (val: Boolean) => emit('input', val),
        })
        const currentStaffServicesSettings = computed(
            () => props.settings.staff.find(el => el.id === props.staffId)?.services || []
        )
        const staff = computed(() => props.staffList.find(el => el.id === props.staffId))
        const title = computed(() => `НАСТРОЙКИ УСЛУГ МАСТЕРА ${_.toUpper(staff.value?.name)}`)
        const existedServicesIds = computed(() => currentStaffServicesSettings.value!.map(el => el.id))
        const openServiceSelect = () => (root.$refs.serviceSelect as any).openMenu()
        const updateService = (currentServiceId: string, newServiceId: string) => {
            emit('update-service', { staffId: props.staffId, currentServiceId, newServiceId })
        }
        const removeService = (serviceId: string) => {
            emit('remove-service', { staffId: props.staffId, serviceId })
        }
        const addStaffService = (serviceId: string) => {
            emit('add-service', { staffId: props.staffId, serviceId })
        }
        const getAvailabeServices = (serviceId?: string) => {
            return props.serviceList.map(el =>
                Object.assign({}, el, {
                    disabled: serviceId === el.id ? false : existedServicesIds.value.includes(el.id),
                })
            )
        }

        return {
            staff,
            title,
            modalIsOpened,
            currentStaffServicesSettings,
            getAvailabeServices,
            openServiceSelect,
            set: _.set,
            toPercent,
            fromPercent,
            checkInputIsNumber,
            removeService,
            addStaffService,
            updateService,
        }
    },
})
