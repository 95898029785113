
import { defineComponent, ref, PropType, getCurrentInstance } from 'vue'
import { WorkingFormatKpiSetting } from '@/types/GCB2'
import TextField from '@/components/inputs/TextField.vue'
export default defineComponent({
    name: 'KpiNameAndDescription',
    components: { TextField },
    props: {
        value: { type: Object as PropType<WorkingFormatKpiSetting>, required: true },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const rules = {
            name: [(v: any) => !!v || 'Обязательно', (v: any) => v.length <= 150 || `Меньше ${150} символов`],
            description: [(v: any) => v.length <= 600 || `Меньше ${600} символов`],
        }

        const formInput = (e: any) => emit('validation-updated', e)

        const clear = () => (root.$refs as any).form.resetValidation()
        return { formInput, clear, rules }
    },
})
